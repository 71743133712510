
body.page_login {
	height:100vh;
	width:100vw;
	position: relative;
	background-color: #f5f5f5;
}

header.page {
	background-color: #f5f5f5;
    border-bottom: 10px solid $primary;
    .navbar {
    	justify-content: space-between;
    	background:transparent;
	    .navbar-brand {
		    .navbar-item {
		    	padding:0;
		    	img {
		    		max-height: inherit;
		    	}
		    }
		    
		}
		.navbar-end {
    		margin-left: inherit;
		}
		h1 {
	    	font-size: 22px;
		    align-items: center;
		    display: flex;
		    text-align: center;
	    }
		.user_is_login {
			background:#e2e2e2;
			padding:20px;
			font-size:12px;
			a {
				color:#4a4a4a;
			}
		}
	}
}



#login {
	position: absolute;
	top:50%;
	left:50%;
	width:300px;
	transform: translate(-50%,-50%);
	.form_login {
		padding:20px;
		background-color: #FFF;
		border-radius:4px;
	}
	.logo {
		img {
			margin:0 auto;
			display: block;
		}
	}
}



.section {
	&.is-grey {
		background:#EEE;
	}
}

h2.titre {
	font-weight: bold;
	span {
		background: #ffdf11;
	    border-radius: 4px;
	    width: 40px;
	    height: 40px;
	    display: inline-block;
	    text-align: center;
	    line-height: 40px;
	    font-weight: bold;
	    font-size: 20px;
	    color: #FFF;
	    margin-right:10px;
	}
	&.titre_2 {
		margin-bottom:30px;
	}
}

.column {
	&.is-custom-60 {
	    flex: none;
	    width: 84px;
	}
}

.box_marque {
	background:#EEE;
	border-radius: 4px;
	padding:10px;
}

.column {
	.is-field {
		line-height: 40px;
		margin-bottom:10px;
		&:last-child {
			margin-bottom:0px;
		}
	}
}

.image_marque {
	height: 60px;
	margin-bottom:10px;
	img {
		height: 100%;
		width:auto;
	}
}

.button {
	font-weight: bold;
	span {
		margin-right: 5px !important;
	}
}
