
.field_input {
    width:100%;
    position: relative;
}

.file-input-wrapper {
    height:40px;
    .button {
        width: 100%;
        height:40px;
        z-index: 5;
        a {
            color:#FFF;
        }
    }
    form {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
    }
    .delete {
        position: absolute;
        right: -5px;
        z-index: 10;
        top: -5px;
        background-color: $dark;
        cursor: pointer;
        &:hover {
            background-color: $dark;
        }
    }
}

.field_input input.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.no-js input.inputfile + label {
    display: none;
}

.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}