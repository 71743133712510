.is-checkbox {
    position: relative;
    width: 60px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select: none;
    input {
        display: none;
    }

    label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border-radius: 4px;
    }
    .switch {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
    }
    .switch:before, .switch:after {
        display: block;
        float: left;
        width: 50%;
        height: 40px;
        padding: 0;
        line-height: 36px;
        font-size: 14px;
        color: white;
        font-family: Arial, sans-serif;
        font-weight: bold;
        box-sizing: border-box;
    }
    .switch:before {
        content: "OUI";
        background-color: #FFDF11;
        color: #FFFFFF;
        border: 2px solid #FFDF11;
        text-align: center;
    }
    .switch:after {
        content: "NON";
        background-color: #FFFFFF;
        border: 2px solid #d8d8d8;
        color: #d8d8d8;
        text-align: center;
    }
    input:checked + label .switch {
        margin-left: 0;
    }
}